<template>
  <div class="box">
    <div class="box-header">
        <div style="text-align: left;margin:  22px 0 0 20px;"><img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju2/favicon.ico" alt="" style="width: .5rem;"> 万物盒中奖领取流程</div>
        <div class="title">点击下方按钮下载</div>
        <div>
            <div class="text" style="margin-top: .2rem;">前往APP-我的-我的仓库-选择-提货</div>
            <div class="downLoad">
                <div class="downLoad_a" @click="dowond_a">
                    <img src="../assets/img/Android_d.png" alt="">
                    <div>Android下载</div>
                </div>
                <!-- <div class="downLoad_i" @click="dowond_i">
                    <img src="../assets/img/iPhone_d.png" alt="">
                    <div>iPhone下载</div>
                </div> -->
            </div>
        </div>
        <div>
            <div class="title" >领取流程</div>
            <div class="rel" style="margin-top: .2rem;">
                <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju_img/caozuo1.jpg" alt="" style="width: 100%;">
                <div class="text">第一步 登录</div>

            </div>
            <div class="rel">
                <div class="text">第二步 进入我的仓库</div>
                <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju_img/caozuo2.jpg" alt="" style="width: 100%;">
            </div>
            <div class="rel">
                <div class="text">第三步 点击盲盒列表进行领取</div>
                <img src="https://wwh-web.oss-cn-beijing.aliyuncs.com/duanju_img/caozuo3.jpg" alt="" style="width: 100%;">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        dowond_a() {
                window.open('https://wanwu.bandiands.com/upload/app/20230310/6380288c871b91422f797475dae05bf0.apk')
            },
            dowond_i() {
                window.open('https://apps.apple.com/cn/app/id1604779545')

            }, 
    }
}
</script>

<style lang="less" scoped> 
body{
    width: 100%;
    height: 100vh;
    background:linear-gradient(to top, rgb(144, 144, 218), pink);; ;
}
.rel{
    position: relative;
    width: 100%;
    height: auto;
    .text{
            position: absolute;
            top: 0;
        }
}
.box-header{
    text-align: center;
    .title{
        display: inline-block;
        font-size: .4rem;
        font-weight: bold;
        color: rgb(31, 102, 255);
        margin-top: .2rem;
        
    }
}
.downLoad{
            margin-bottom: 0.83rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            >div{
                width: 4.53rem;
            margin-top: 0.2rem;

                height: 1.17rem;
                border: 1px solid #1B1B1B;
                border-radius: 0.59rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.31rem 0.77rem;
                box-sizing: border-box;
                font-size: 0.37rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #022222;

            }
            .downLoad_a{
            }

        }

</style>