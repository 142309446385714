<template>
  <div class="bodyBox">
    <div class="title">
        提交订单
    </div>
    <div class="flex_box">
        <div style="width: 5rem;height:5rem"><img :src="detailsData.cover_pic"  alt="" style="width: 100%;height:100%"></div>
        <div class="nameFlex">
            <div style="width: 100%;">
                <div class="textOne">{{detailsData.name}}</div>
                <div class="color_red">￥{{detailsData.price}}</div>
            </div>
        </div>
    </div>
    <div>
        <van-form>
          <van-cell-group inset>
            <van-field
              v-model="phone"
              type="number"
              name="电话号码"
              label="电话号码"
              placeholder="电话号码"
              :rules="[{ required: true, message: '请填写电话号码' }]"
            />
            <van-field
              v-model="username"
              name="填写姓名"
              label="填写姓名"
              placeholder="填写姓名"
              :rules="[{ required: true, message: '请填写您的姓名' }]"
            />
            <van-field
              v-model="result"
              is-link
              readonly
              name="picker"
              label="所属城市"
              placeholder="点击选择城市"
              @click="showPicker = true"
            />
            <van-field
              v-model="addrss_detail"
              name="详细地址"
              label="详细地址"
              placeholder="详细地址"
              :rules="[{ required: true, message: '请填写详细地址' }]"
            />

            <div style="font-size: 0.3rem; color: red;margin: var(--van-cell-group-inset-padding);">
                注意:提交订单信息支付完成后，自动提货，系统将在1周内完成发货，填写地址错误可能导致发货不准确，请在本周内注意查看手机短信，支付完成后会发送短信通知您所中奖商品
            </div>
          </van-cell-group>
        </van-form>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :columns="columns"
        :columns-field-names="{ text: 'label' }"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            price_view:'',
            box_id:'',
            detailsData:{},
            phone:'',
            username:'',
            result:'',
            addrss_detail:'',
            showPicker:false
        }
    },
    created(){
        this.box_id=this.$route.query.goods_id
        this.getBoxList()
        this.getCityList()
    },
    methods:{
        getCityList() {
      axios.get("/api/v1/default/districtList").then((res) => {
        console.log(res.data.data);
        this.columns = res.data.data;
      });
    },
    onConfirm(e) {
      this.result = "";
      this.resetObj();
      e.selectedOptions.forEach((element, index) => {
        if (index == e.selectedOptions.length - 1) {
          this.result += element.label;
        } else {
          this.result += element.label + "-";
        }
      });
      this.tihuoObj = {
        province: e.selectedOptions[0].label,
        province_id: e.selectedOptions[0].value,
        city: e.selectedOptions[1].label,
        city_id: e.selectedOptions[1].value,
        district: e.selectedOptions[2].label,
        district_id: e.selectedOptions[2].value,
      };
      this.showPicker = false;
      console.log(e);
    },
        getQueryString(name) {
              var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
              var r = window.location.search.substr(1).match(reg);
              if (r != null) {
                  return unescape(r[2]);
              }
              return '';
          },
        phoneOs () {
              var u = navigator.userAgent,
  
              isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
  
              isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  
              if (isAndroid) {
                  return 'Android'
              }
  
              if (isiOS) {
                  return 'IOS'
              }
              return ''
          },
        getBoxList() {
            
            axios.get(`${window.location.origin}/api/v1/box/index?box_id=${this.box_id}`).then(res => {
                if(res.data.code == 0) {
                    
                    this.price_view=res.data.data.box.price
                    this.detailsData=res.data.data.box
                    
                }
            })
            axios.post(`${window.location.origin}/api/v1/channel/getPayChannel`,{
                equipment_client: this.phoneOs() == 'IOS'?3:2,
                pay_client: 3, //3: h5  2:xiaochengxu  1:app
                channel_no: this.getQueryString('channel_no')
            }).then(res=>{
                this.payIcon=res.data.data.pay_way
            })
        },
    }
}
</script>

<style  scoped>
.bodyBox{
    font-size: .35rem;
}
.title{
    font-size: .5rem;
    font-weight: 600;
    text-align: center;
    padding: 10px 0;
}

.flex_box{
    display: flex;
    align-items: center;

}
.nameFlex{
    flex: 1;
    display: flex;
    align-items: center;
}
.color_red{
    margin-top: .2rem;
    color: red;
    font-size: .4rem;
}
.textOne{
    width: 4rem;
    overflow: hidden;
text-overflow:ellipsis;
white-space: nowrap;
}

</style>